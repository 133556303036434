.Home-Page {
    padding: 2rem;
    width: 100%;
}

.Home-Container {
    display: flex;
    flex-direction: column;
}

.Home-StandWithUkraine {
    width: 100%;
    text-align: center;
}

.Home-StandWithUkraineImage {
    height: 20px;
    width: 300px;
}

.Home-TitleItem {
    width: 100%;
    text-align: center;
}

.Home-TitleText {
    margin: 0rem;
    font-size: 2rem;
}

.Home-Tabs {
    width: 100%;
    text-align: center;
    padding: 1rem;
}

.Home-Items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
}

.Home-Image {
    width: 335px;
    pointer-events: none;
}

.Home-Video {
    padding: 0.5rem 0rem 0.25rem 0rem;
    width: 335px;
    height: 595px;
}  