.NotFoundPage-Body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.NotFoundPage-LogoImage {
    width: 200px;
}

.NotFoundPage-NotFoundMessageText {
    font-size: 28px;
}