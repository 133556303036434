.HowToLinks-Container {
    padding: 1rem;
    display: flex;
    flex-direction: column !important;
}

.HowToLinks-ButtonItem {
    padding: 0.25rem 0rem 0.25rem 0rem;
    width: 335px;
}

.HowToLinks-Button {
    width: 100%;
}
