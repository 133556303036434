.PrivacyPolicy-Container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 60%;
    min-width: 335px;
}

.PrivacyPolicy-SubheaderItem {
    width: 100%;
}

.PrivacyPolicy-SubHeader {
    font-size: 24px;
    font-weight: 800;
}

.PrivacyPolicy-TextItem {
    width: 100%;
}

.PrivacyPolicy-Text {
    margin: 0px !important;
    padding: 0px !important;
}

.PrivacyPolicy-Link {
    color: blue;
    cursor: pointer;
}