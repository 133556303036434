.ContactUs-Container {
    width: 50%;
    min-width: 315px;
}

.ContactUs-EmailForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.ContactUs-FormInput {
    width: 100%;
}

.ContactUs-Button {
    margin-top: 10px;
}